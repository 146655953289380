module.exports = {
  messages: {
    A11Y_LOGO: 'Zur XING Startseite',
    A11Y_SETTINGS_DROPDOWN_LABEL: 'Einstellungen verwalten',
    ACTIVE_LANGUAGE: 'Deutsch',
    ACTIVITY_CENTER_CTA: 'Anmelden & speichern',
    ACTIVITY_CENTER_EMPTY_STATE_HEADLINE: 'Deine Aktivitäten',
    ACTIVITY_CENTER_EMPTY_STATE_TEXT:
      'Hier findest Du Deine Aktivitäten und kannst sie in Deinem XING Profil für später speichern.',
    ACTIVITY_CENTER_FOOTER_TEXT:
      'Deine Aktivitäten werden bis zu 14 Tage zwischengespeichert.',
    ACTIVITY_CENTER_HEADLINE: 'Deine Aktivitäten',
    ACTIVITY_CENTER_NOTIFICATION_MESSAGE: 'Du hast einen Job gespeichert.',
    ACTIVITY_CENTER_NOTIFICATION_OPEN: 'Jetzt ansehen',
    ACTIVITY_CENTER_RECENTLY_VIEWED_JOBS_TITLE: 'Kürzlich angesehene Jobs',
    ACTIVITY_CENTER_SAVED_JOBS_TITLE: 'Gemerkte Jobs',
    ACTIVITY_CENTER_TEXT:
      'Hier findest Du Deine letzten Aktivitäten. Mit einem kostenlosen XING Profil kannst Du diese länger speichern.',
    ARMSTRONG_DISCO_NAVIGATION_ICON_TEXT: 'Austausch',
    BOTTOM_BAR_FIND_JOBS_LABEL: 'Jobs finden',
    BOTTOM_BAR_INSIGHTS_LABEL: 'Insights',
    BOTTOM_BAR_NETWORK_LABEL: 'Netzwerk',
    BOTTOM_BAR_NOTIFICATIONS_LABEL: 'Mitteilungen',
    BOTTOM_BAR_YOUR_JOBS_LABEL: 'Deine Jobs',
    BUTTON_TRY_AGAIN: 'Noch einmal versuchen',
    FOOTER_LANGUAGES: 'Sprache',
    FRAME_HEADER_SEARCH: 'Suche',
    FRAME_HEADER_UPSELL_PREMIUM: 'Premium werden',
    FRAME_HEADER_UPSELL_PROJOBS: 'ProJobs entdecken',
    HEADER_SKIP_NAVIGATION: 'Navigation überspringen',
    MAINNAV_ACTIVITY_CENTER: 'Deine Aktivitäten',
    MAINNAV_BUSINESS_SOLUTIONS: 'Business-Lösungen',
    MAINNAV_BUSINESS_SOLUTIONS_ADVERTISING: 'Werben mit XING',
    MAINNAV_BUSINESS_SOLUTIONS_RECRUITING: 'Recruiting mit XING',
    MAINNAV_COMPANIES: 'Unternehmen',
    MAINNAV_INSIGHTS: 'Insights',
    MAINNAV_JOBS_FIND: 'Jobs finden',
    MAINNAV_LOGIN: 'Anmelden',
    MAINNAV_MESSAGES: 'Nachrichten',
    MAINNAV_MYJOBS: 'Deine Jobs',
    MAINNAV_NETWORK: 'Netzwerk',
    MAINNAV_PRODUCTS: 'Produkte',
    MAINNAV_PRODUCTS_MENU_ADVERTISING: 'AdManager',
    MAINNAV_PRODUCTS_MENU_ADVERTISING_DESCRIPTION:
      'Erfolgreich werben auf XING',
    MAINNAV_PRODUCTS_MENU_ADVERTISING_UPSELL: 'Werben auf XING',
    MAINNAV_PRODUCTS_MENU_BRAND_MANAGER: 'BrandManager',
    MAINNAV_PRODUCTS_MENU_BRAND_MANAGER_DESCRIPTION:
      'Unternehmensprofile verwalten',
    MAINNAV_PRODUCTS_MENU_HEADLINE: 'Produkte & Services',
    MAINNAV_PRODUCTS_MENU_JOBS_POSTER: 'Stellenanzeigen',
    MAINNAV_PRODUCTS_MENU_JOBS_POSTER_DESCRIPTION:
      'Stellenanzeigen veröffentlichen',
    MAINNAV_PRODUCTS_MENU_JOBS_POSTER_UPSELL: 'Stellenanzeigen erstellen',
    MAINNAV_PRODUCTS_MENU_LEBENSLAUF: 'Lebenslauf erstellen',
    MAINNAV_PRODUCTS_MENU_LEBENSLAUF_DESCRIPTION: 'Ein Service von XING',
    MAINNAV_PRODUCTS_MENU_RECRUITING_360_UPSELL: 'Recruiting mit XING 360',
    MAINNAV_PRODUCTS_MENU_RECRUITING_360_UPSELL_BESTSELLER_BADGE: 'Bestseller',
    MAINNAV_PRODUCTS_MENU_RECRUITING_UPSELL: 'Recruiting mit XING',
    MAINNAV_PRODUCTS_MENU_SEAT_MANAGER: 'LizenzManager',
    MAINNAV_PRODUCTS_MENU_SEAT_MANAGER_DESCRIPTION:
      'Premium-Mitgliedschaften verwalten',
    MAINNAV_PRODUCTS_MENU_TALENT_MANAGER: 'TalentManager',
    MAINNAV_PRODUCTS_MENU_TALENT_MANAGER_DESCRIPTION:
      'Active Sourcing in D-A-CH',
    MALT_FOOTER_COPYRIGHT_API: '© {siteoperator} | Alle Rechte vorbehalten',
    MEMENU_FOLLOWING_LABEL: 'Folgst Du',
    MEMENU_HELP_LABEL: 'Hilfe',
    MEMENU_JOB_ADS_LABEL: 'Stellenanzeigen verwalten',
    MEMENU_JOB_PREFERENCES_LABEL: 'Job-Wünsche',
    MEMENU_MY_POSTS_LABEL: 'Deine Beiträge',
    MEMENU_PREMIUM_FOR_BASIC_USER_LABEL: 'Premium werden',
    MEMENU_PREMIUM_LABEL: 'Premium',
    MEMENU_PROBUSINESS_LABEL: 'ProBusiness',
    MEMENU_PROFILE_LABEL: 'Profil',
    MEMENU_PROJOBS_FOR_BASIC_USER_LABEL: 'ProJobs werden',
    MEMENU_PROJOBS_LABEL: 'ProJobs',
    MEMENU_SETTINGS_LABEL: 'Einstellungen',
    NOTIFICATION_CENTER_LINK: 'Mitteilungen',
    SEARCH_DROPDOWN_COMPANIES: 'Unternehmen',
    SEARCH_DROPDOWN_JOBS: 'Jobs',
    SEARCH_DROPDOWN_MEMBERS: 'Mitglieder',
    SEARCH_DROPDOWN_NEWS: 'News',
    USER_FLAG_BUTTON_ARIA_LABEL: 'Markierung für XING {type}',
    VERTICAL_NAV_BUSINESS_SOLUTIONS_LABEL: 'Business-Lösungen',
    VERTICAL_NAV_BUSINESS_SOLUTIONS_SHORT_LABEL: 'Business',
    VERTICAL_NAV_FIND_JOBS_LABEL: 'Jobs finden',
    VERTICAL_NAV_FOLLOWING_LABEL: 'Folgst Du',
    VERTICAL_NAV_INSIGHTS_LABEL: 'Insights',
    VERTICAL_NAV_JOB_PREFERENCES_LABEL: 'Job-Wünsche',
    VERTICAL_NAV_MESSAGES_LABEL: 'Nachrichten',
    VERTICAL_NAV_MY_POSTS_LABEL: 'Deine Beiträge',
    VERTICAL_NAV_NETWORK_LABEL: 'Netzwerk',
    VERTICAL_NAV_NETWORK_SHORT_LABEL: 'Netzwerk',
    VERTICAL_NAV_PREMIUM_FOR_BASIC_USER_LABEL: 'Premium werden',
    VERTICAL_NAV_PREMIUM_LABEL: 'Premium',
    VERTICAL_NAV_PREMIUM_SHORT_LABEL: 'Premium',
    VERTICAL_NAV_PROBUSINESS_LABEL: 'ProBusiness',
    VERTICAL_NAV_PROFILE_LABEL: 'Profil',
    VERTICAL_NAV_PROJOBS_FOR_BASIC_USER_LABEL: 'ProJobs werden',
    VERTICAL_NAV_PROJOBS_LABEL: 'ProJobs',
    VERTICAL_NAV_YOUR_JOBS_LABEL: 'Deine Jobs',
    VERTICAL_NAVIGATION_COMPANIES: 'Unternehmen',
    VERTICAL_NAVIGATION_FOOTER_ADVERTISE: 'Werben auf XING',
    VERTICAL_NAVIGATION_FOOTER_ANDROID: 'Android',
    VERTICAL_NAVIGATION_FOOTER_ANSCHREIBEN: 'Anschreiben-Editor',
    VERTICAL_NAVIGATION_FOOTER_APP_GALLERY: 'Mobile & Desktop Apps',
    VERTICAL_NAVIGATION_FOOTER_APPS: 'Apps',
    VERTICAL_NAVIGATION_FOOTER_BEWERBUNG: 'Bewerbungsratgeber',
    VERTICAL_NAVIGATION_FOOTER_CAREER: 'Karriere',
    VERTICAL_NAVIGATION_FOOTER_CAREER_GUIDE: 'Karriere-Ratgeber',
    VERTICAL_NAVIGATION_FOOTER_COACHES: 'Coaches + Trainer',
    VERTICAL_NAVIGATION_FOOTER_COMPANIES: 'Unternehmen',
    VERTICAL_NAVIGATION_FOOTER_COMPANY: 'Unternehmen',
    VERTICAL_NAVIGATION_FOOTER_DEVBLOG: 'Devblog',
    VERTICAL_NAVIGATION_FOOTER_HALLO_FREELANCER: 'Freelancer verwalten',
    VERTICAL_NAVIGATION_FOOTER_HELP: 'Hilfe & Kontakt',
    VERTICAL_NAVIGATION_FOOTER_IMPRINT: 'Impressum',
    VERTICAL_NAVIGATION_FOOTER_INVESTOR_RELATIONS: 'Investor Relations',
    VERTICAL_NAVIGATION_FOOTER_IPHONE_IPAD: 'iPhone & iPad',
    VERTICAL_NAVIGATION_FOOTER_JOBS_DIRECTORY: 'Jobs Verzeichnis',
    VERTICAL_NAVIGATION_FOOTER_KUNUNU: 'Arbeitgeber-Bewertungen',
    VERTICAL_NAVIGATION_FOOTER_LEBENSLAUF: 'Lebenslauf-Editor',
    VERTICAL_NAVIGATION_FOOTER_MEMBERSHIP_ADMINISTRATION:
      'Mitgliedschaften verwalten',
    VERTICAL_NAVIGATION_FOOTER_MEMBERSHIP_CANCELLATION:
      'Mitgliedschaft kündigen',
    VERTICAL_NAVIGATION_FOOTER_MEMBERSHIPS: 'Mitgliedschaften',
    VERTICAL_NAVIGATION_FOOTER_NEW_WORK_EXPERIENCE:
      'NWX – Neues zur Zukunft der Arbeit',
    VERTICAL_NAVIGATION_FOOTER_NEWWORKSE: 'New Work SE',
    VERTICAL_NAVIGATION_FOOTER_PEOPLE_DIRECTORY: 'Mitgliederverzeichnis',
    VERTICAL_NAVIGATION_FOOTER_PRAKTIKUM_GUIDE: 'Praktikum-Guide',
    VERTICAL_NAVIGATION_FOOTER_PREMIUM_MEMBERSHIP: 'Premium',
    VERTICAL_NAVIGATION_FOOTER_PRESS: 'Presse',
    VERTICAL_NAVIGATION_FOOTER_PRIVACY: 'Datenschutz bei XING',
    VERTICAL_NAVIGATION_FOOTER_PRIVACY_POLICY: 'Datenschutzerklärung',
    VERTICAL_NAVIGATION_FOOTER_PROJOBS_MEMBERSHIP: 'ProJobs',
    VERTICAL_NAVIGATION_FOOTER_RESOURCES: 'Ressourcen',
    VERTICAL_NAVIGATION_FOOTER_TALENT_MANAGER: 'Personalgewinnung mit XING',
    VERTICAL_NAVIGATION_FOOTER_TANDC: 'AGB',
    VERTICAL_NAVIGATION_FOOTER_XAS: 'Werbeanzeige erstellen',
    VERTICAL_NAVIGATION_FOOTER_XING_NEWS: 'XING News',
    VERTICAL_NAVIGATION_FOOTER_YOUR_XING: 'Hauptbereiche',
    VERTICAL_NAVIGATION_HEADER_BRAND_MANAGER_EDITOR: 'BrandManager',
    VERTICAL_NAVIGATION_HEADER_HELP: 'Hilfe',
    VERTICAL_NAVIGATION_HEADER_MANAGE_JOBS: 'Stellenanzeigen',
    VERTICAL_NAVIGATION_HEADER_PERSONAL_ADS: 'AdManager',
    VERTICAL_NAVIGATION_HEADER_PERSONAL_COACH_PROFILE: 'Coach-Profil',
    VERTICAL_NAVIGATION_HEADER_PERSONAL_LOGOUT: 'Ausloggen',
    VERTICAL_NAVIGATION_HEADER_PERSONAL_MEMBERSHIPS_INVOICES:
      'Mitgliedschaften & Rechnungen',
    VERTICAL_NAVIGATION_HEADER_PERSONAL_PREMIUM_MEMBERSHIP: 'Premium',
    VERTICAL_NAVIGATION_HEADER_PERSONAL_PROJOBS_MEMBERSHIP: 'ProJobs',
    VERTICAL_NAVIGATION_HEADER_PERSONAL_SETTINGS: 'Einstellungen',
    VERTICAL_NAVIGATION_HEADER_SEAT_MANAGER_ADMIN: 'LizenzManager',
    VERTICAL_NAVIGATION_HEADER_XTM: 'TalentManager',
    VERTICAL_NAVIGATION_MESSAGES: 'Nachrichten',
    X_UI_HEADER_REGISTER: 'Registrieren',
    X_UI_NAVIGATION_LOGIN_FRAME_FALLBACK: 'Einloggen',
    XDS_FLAG_AMBASSADOR_DIVERSE: 'Ambassadx',
    XDS_FLAG_AMBASSADOR_FEMALE: 'Ambassadorin',
    XDS_FLAG_AMBASSADOR_MALE: 'Ambassador',
    XDS_FLAG_AMBASSADOR_NEUTRAL: 'Ambassador',
    XDS_FLAG_BASIC_DIVERSE: 'Basis',
    XDS_FLAG_BASIC_FEMALE: 'Basis',
    XDS_FLAG_BASIC_MALE: 'Basis',
    XDS_FLAG_BASIC_NEUTRAL: 'Basis',
    XDS_FLAG_BETA_DIVERSE: 'Beta',
    XDS_FLAG_BETA_FEMALE: 'Beta',
    XDS_FLAG_BETA_MALE: 'Beta',
    XDS_FLAG_BETA_NEUTRAL: 'Beta',
    XDS_FLAG_INSIDER_DIVERSE: 'Insidx',
    XDS_FLAG_INSIDER_FEMALE: 'Insiderin',
    XDS_FLAG_INSIDER_MALE: 'Insider',
    XDS_FLAG_INSIDER_NEUTRAL: 'Insider',
    XDS_FLAG_MODERATOR_DIVERSE: 'Moderatx',
    XDS_FLAG_MODERATOR_FEMALE: 'Moderatorin',
    XDS_FLAG_MODERATOR_MALE: 'Moderator',
    XDS_FLAG_MODERATOR_NEUTRAL: 'Moderator',
    XDS_FLAG_NEW_DIVERSE: 'Neu',
    XDS_FLAG_NEW_FEMALE: 'Neu',
    XDS_FLAG_NEW_MALE: 'Neu',
    XDS_FLAG_NEW_NEUTRAL: 'Neu',
    XDS_FLAG_PREMIUM_DIVERSE: 'Premium',
    XDS_FLAG_PREMIUM_FEMALE: 'Premium',
    XDS_FLAG_PREMIUM_MALE: 'Premium',
    XDS_FLAG_PREMIUM_NEUTRAL: 'Premium',
    XDS_FLAG_PROBUSINESS_DIVERSE: 'ProBusiness',
    XDS_FLAG_PROBUSINESS_FEMALE: 'ProBusiness',
    XDS_FLAG_PROBUSINESS_MALE: 'ProBusiness',
    XDS_FLAG_PROBUSINESS_NEUTRAL: 'ProBusiness',
    XDS_FLAG_PROCOACH_DIVERSE: 'ProCoach',
    XDS_FLAG_PROCOACH_FEMALE: 'ProCoach',
    XDS_FLAG_PROCOACH_MALE: 'ProCoach',
    XDS_FLAG_PROCOACH_NEUTRAL: 'ProCoach',
    XDS_FLAG_PROJOBS_DIVERSE: 'ProJobs',
    XDS_FLAG_PROJOBS_FEMALE: 'ProJobs',
    XDS_FLAG_PROJOBS_MALE: 'ProJobs',
    XDS_FLAG_PROJOBS_NEUTRAL: 'ProJobs',
    XDS_FLAG_PROTRAINER_DIVERSE: 'ProTrainx',
    XDS_FLAG_PROTRAINER_FEMALE: 'ProTrainerin',
    XDS_FLAG_PROTRAINER_MALE: 'ProTrainer',
    XDS_FLAG_PROTRAINER_NEUTRAL: 'ProTrainer',
  },
};
